import { useContext, useEffect, useState } from "react";
import { CartContext, SettingsContext } from "../App";
import currencyFormat from "./functions/functions";
import QtySelector from "./QtySelector";
import CloseButton from "./CloseButton";
import Label from "./Label";
import "../css/Variants.css";

function Variants({ product }) {
	const { settings, setSelectedProduct } = useContext(SettingsContext);
	const { add_product } = useContext(CartContext);
	const [buttonEnabled, serButtonEnabled] = useState(false);
	const [finalPrice, setFinalPrice] = useState(product.price);
	const [errorEl, setErrorEl] = useState(product.price);

	useEffect(() => {
		checkVariants();
	}, []);

	const checkVariants = () => {
		console.log("checkVariants");
		let valid = true;
		let price = product.discounted
			? parseFloat(product.discount.price)
			: parseFloat(product.price);
		let firts_element_error = false;
		const variants_categories = document.querySelectorAll(".variants-category");
		variants_categories.forEach((variants_category) => {
			const variants = variants_category.querySelectorAll(".variant");
			let selected = 0;
			variants.forEach((variant) => {
				variant.querySelectorAll("button").forEach((input) => {
					if (input.classList.contains("checked")) {
						selected++;
						price += parseFloat(variant.dataset.price);
					}
				});
				variant.querySelectorAll('input[type="checkbox"]:checked').forEach((input) => {
					selected++;
					price += parseFloat(variant.dataset.price);
				});
				variant.querySelectorAll(".qty").forEach((input) => {
					selected += parseInt(input.dataset.qty);
					price += parseInt(input.dataset.qty) * parseFloat(variant.dataset.price);
				});
			});
			if (selected < parseInt(variants_category.dataset.min)) {
				valid = false;
				//variants_category.style.backgroundColor = "#ffeeee";
				variants_category.classList.add("alert");
				if (!firts_element_error) firts_element_error = variants_category;
			} else {
				//variants_category.style.backgroundColor = "#ffffff";
				variants_category.classList.remove("alert");
			}
			variants.forEach((variant) => {
				variant.querySelectorAll('input[type="checkbox"]').forEach((input) => {
					if (selected >= variants_category.dataset.max) {
						if (!input.checked) input.disabled = true;
					} else {
						input.disabled = false;
					}
				});
				variant.querySelectorAll(".qty-selector").forEach((qty_box) => {
					if (selected >= variants_category.dataset.max) {
						//if (!qty_box.checked) qty_box.disabled = true;
						//qty_box.querySelector("button.plus").style.visibility = "hidden";
						const plusButton = qty_box.querySelector("button.plus");
						plusButton.classList.remove("enabled");
						//plusButton.disabled = true;
					} else {
						//qty_box.querySelector("button.plus").style.visibility = "visible";
						const plusButton = qty_box.querySelector("button.plus");
						plusButton.classList.add("enabled");
						//plusButton.disabled = false;
					}
				});
			});
		});
		serButtonEnabled(valid);
		setFinalPrice(price);
		setErrorEl(firts_element_error);
	};

	const handleAddToCart = () => {
		const variants_categories = document.querySelectorAll(".variants-category");
		let selectedVariants = [];
		let selectedSubproducts = [];
		variants_categories.forEach((variants_category) => {
			const variants = variants_category.querySelectorAll(".variant");
			variants.forEach((variant) => {
				variant.querySelectorAll("button.checked").forEach((input) => {
					if (variant.classList.contains("subproduct")) {
						selectedSubproducts.push({
							variant_category_id: variants_category.dataset.id,
							id: variant.dataset.id,
							qty: 1,
						});
					} else {
						selectedVariants.push({
							variant_category_id: variants_category.dataset.id,
							id: variant.dataset.id,
							qty: 1,
						});
					}
				});
				variant.querySelectorAll('input[type="checkbox"]:checked').forEach((input) => {
					if (variant.classList.contains("subproduct")) {
						selectedSubproducts.push({
							variant_category_id: variants_category.dataset.id,
							id: variant.dataset.id,
							qty: 1,
						});
					} else {
						selectedVariants.push({
							variant_category_id: variants_category.dataset.id,
							id: variant.dataset.id,
							qty: 1,
						});
					}
				});
				variant.querySelectorAll(".qty").forEach((input) => {
					if (input.dataset.qty > 0) {
						if (variant.classList.contains("subproduct")) {
							selectedSubproducts.push({
								variant_category_id: variants_category.dataset.id,
								id: variant.dataset.id,
								qty: input.dataset.qty,
							});
						} else {
							selectedVariants.push({
								variant_category_id: variants_category.dataset.id,
								id: variant.dataset.id,
								qty: input.dataset.qty,
							});
						}
					}
				});
			});
		});
		let notes = "";
		const ingredients = document.querySelectorAll(".ingredient input[type='checkbox']");
		console.log("ingredients", ingredients);
		ingredients.forEach((ingredient) => {
			console.log(ingredient);
			console.log(ingredient.checked);
			if (ingredient.checked) notes += "No " + ingredient.dataset.name + "<br>";
			console.log(notes);
		});
		console.log(notes);
		add_product({
			product_id: product.id,
			qty: 1,
			variants: selectedVariants,
			products: selectedSubproducts,
			notes: notes,
			has_price: product.price > 0 ? 1 : 0,
		});
	};

	return (
		<div id="variants" className="popup">
			<div className="content">
				<div className="popup-header">
					<CloseButton onClick={() => setSelectedProduct(false)} />
					<h3 className="mb-0">{product.name}</h3>
					{currencyFormat(finalPrice, false)}
				</div>
				<div className="popup-scroll">
					<div className="popup-content">
						{(product.variants_categories.length > 0 ||
							product.ingredients.length > 0) && (
								<>
									<div
										className="subtitle"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={43} />
									</div>

									<div className="variants-categories">
										{product.variants_categories.map((variants_category) => (
											<div
												key={variants_category.id}
												className="variants-category"
												data-id={variants_category.id}
												data-min={variants_category.min}
												data-max={variants_category.max}
											>
												<div className="variants-category-title">
													{variants_category.name}
												</div>
												<div className="small mb-1">
													Min: {variants_category.min} | Max:{" "}
													{variants_category.max}
												</div>
												{variants_category.max > 1 &&
													variants_category.force_mono == 0 ? (
													<div className="variants multi">
														{variants_category.variants.map(
															(variant, i) => (
																<div
																	key={"v-" + i}
																	className="variant"
																	data-id={variant.id}
																	data-price={
																		variant.price
																			? variant.price
																			: 0
																	}
																>
																	<QtySelector
																		variant={variant}
																		onChange={checkVariants}
																		hasPrice={
																			variants_category.has_price
																		}
																	/>
																</div>
															)
														)}
														{variants_category.variants2.map(
															(variant, i) => (
																<div
																	key={"v2-" + i}
																	className="variant"
																	data-id={variant.id}
																	data-price={
																		variant.price
																			? variant.price
																			: 0
																	}
																>
																	<QtySelector
																		variant={variant}
																		onChange={checkVariants}
																		hasPrice={
																			variants_category.has_price
																		}
																	/>
																</div>
															)
														)}
														{variants_category.products.map(
															(subproduct, i) => (
																<div
																	key={"s-" + i}
																	className="variant subproduct"
																	data-id={subproduct.id}
																	data-price={subproduct.price}
																>
																	{subproduct.img && (
																		<img src={subproduct.img} />
																	)}
																	<QtySelector
																		variant={subproduct}
																		onChange={checkVariants}
																		hasPrice={
																			variants_category.has_price
																		}
																	/>
																</div>
															)
														)}
													</div>
												) : variants_category.force_mono == 1 ? (
													<Checkbox
														variants_category={variants_category}
														onchange={checkVariants}
													/>
												) : (
													<Radio
														variants_category={variants_category}
														onchange={checkVariants}
													/>
												)}
											</div>
										))}
										{product.ingredients.length > 0 &&
											product.ingredients.filter((i) => i.removable == 1).length >
											0 && (
												<div className="ingredients-container">
													<div className="variants-category-title">
														<Label number={42} />
													</div>
													<div className="small mb-1">
														<Label number={99} />
													</div>
													<div className="ingredients">
														{product.ingredients.map(
															(ingredient) =>
																ingredient.removable == 1 && (
																	<div
																		className="ingredient"
																		key={"ing-" + ingredient.id}
																	>
																		<input
																			type="checkbox"
																			value={ingredient.name}
																			data-name={ingredient.name}
																			disabled={
																				ingredient.removable ==
																				0
																			}
																			//defaultChecked="checked"
																			id={"ing-" + ingredient.id}
																		/>
																		<span className="checkbox_checkmark"></span>
																		<label
																			htmlFor={
																				"ing-" + ingredient.id
																			}
																			className="variant-title"
																		>
																			<Label number={118} />{" "}
																			{ingredient.name}
																		</label>
																	</div>
																)
														)}
													</div>
												</div>
											)}
									</div>
								</>
							)}
					</div>
				</div>
				<div
					className="finalPrice"
					style={{
						backgroundColor: settings.selfordering.color3,
						color: settings.selfordering.color4,
					}}
				>
					{currencyFormat(finalPrice, true)}
				</div>
				<div className="buttons">
					<button className="button" onClick={() => setSelectedProduct(false)}>
						<Label number={6} />
					</button>
					{product.disabled != 1 && buttonEnabled ? (
						<button
							className="button"
							style={{
								backgroundColor: settings.selfordering.color1,
								color: settings.selfordering.color2,
							}}
							onClick={handleAddToCart}
						>
							<Label number={15} />
						</button>
					) : (
						<button
							className="button"
							onClick={() => {
								if (errorEl) errorEl.scrollIntoView({ behavior: "smooth" });
							}}
							style={{
								backgroundColor: settings.selfordering.color1,
								color: settings.selfordering.color2,
							}}
						>
							<Label number={15} />
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

function Checkbox({ variants_category, onchange }) {
	return (
		<div className="variants">
			<div className="variants multi">
				{variants_category.variants.map((variant, i) => (
					<div
						key={"v-" + i}
						className="variant"
						data-id={variant.id}
						data-price={variant.price}
					>
						<input
							type="checkbox"
							id={"var-" + variants_category.id + "-" + variant.id}
							onChange={onchange}
						/>
						<span className="checkbox_checkmark"></span>
						<label
							htmlFor={"var-" + variants_category.id + "-" + variant.id}
							className="variant-title"
						>
							{variant.name}
							{variants_category.has_price == 1 && (
								<div className="price">{" +" + currencyFormat(variant.price)}</div>
							)}
						</label>
					</div>
				))}
				{variants_category.variants2.map((variant, i) => (
					<div
						key={"v2-" + i}
						className="variant"
						data-id={variant.id}
						data-price={variant.price}
					>
						<input
							type="checkbox"
							id={"var-" + variants_category.id + "-" + variant.id}
							onChange={onchange}
							defaultChecked={parseInt(variant.is_default) > 0 ? true : false}
						/>
						<span className="checkbox_checkmark"></span>
						<label
							htmlFor={"var-" + variants_category.id + "-" + variant.id}
							className="variant-title"
						>
							{variant.name}
							{variants_category.has_price == 1 && (
								<span className="small">
									{" +" + currencyFormat(variant.price)}
								</span>
							)}
						</label>
					</div>
				))}
				{variants_category.products.map((subproduct, i) => (
					<div
						key={"s-" + i}
						className="variant subproduct"
						data-id={subproduct.id}
						data-price={subproduct.price}
					>
						<input
							type="checkbox"
							id={"var-" + variants_category.id + "-" + subproduct.id}
							onChange={onchange}
							defaultChecked={variants_category.products.length == 1 ? "checked" : ""}
						/>
						<span className="checkbox_checkmark"></span>
						{subproduct.img && <img src={subproduct.img} />}
						<label
							htmlFor={"var-" + variants_category.id + "-" + subproduct.id}
							className="variant-title"
						>
							{subproduct.name}
							{variants_category.has_price == 1 && (
								<span className="small">
									{" +" + currencyFormat(subproduct.price)}
								</span>
							)}
						</label>
					</div>
				))}
			</div>
		</div>
	);
}

function Radio({ variants_category, onchange }) {
	const handleClick = (event) => {
		console.log("handleClick");
		const el = event.target;
		console.log("checked", el.classList.contains("checked"));
		const wasChecked = el.classList.contains("checked");
		document
			.querySelectorAll("[name=" + el.name + "]")
			.forEach((e) => e.classList.remove("checked"));
		console.log("checked", el.classList.contains("checked"));
		if (!wasChecked) el.classList.add("checked");
		onchange();
	};

	return (
		<div className="variants">
			<div className="variants multi">
				{variants_category.variants.map((variant, i) => (
					<div
						key={"v-" + i}
						className="variant"
						data-id={variant.id}
						data-price={variant.price}
					>
						<button
							id={"var-" + variants_category.id + "-" + variant.id}
							name={"var-" + variants_category.id}
							className="radio"
							onClick={handleClick}
						/>
						<label
							htmlFor={"var-" + variants_category.id + "-" + variant.id}
							className="variant-title"
						>
							{variant.name}
							{variants_category.has_price == 1 && (
								<div className="price">{" +" + currencyFormat(variant.price)}</div>
							)}
						</label>
					</div>
				))}
				{variants_category.variants2.map((variant, i) => (
					<div
						key={"v2-" + i}
						className="variant"
						data-id={variant.id}
						data-price={variant.price}
					>
						<button
							id={"var-" + variants_category.id + "-" + variant.id}
							name={"var-" + variants_category.id}
							className="radio"
							onClick={handleClick}
						/>
						<label
							htmlFor={"var-" + variants_category.id + "-" + variant.id}
							className="variant-title"
						>
							{variant.name}
							{variants_category.has_price == 1 && (
								<span className="small">
									{" +" + currencyFormat(variant.price)}
								</span>
							)}
						</label>
					</div>
				))}
				{variants_category.products.map((subproduct, i) => (
					<div
						key={"s-" + i}
						className="variant subproduct"
						data-id={subproduct.id}
						data-price={subproduct.price}
					>
						<button
							id={"var-" + variants_category.id + "-" + subproduct.id}
							name={"var-" + variants_category.id}
							className="radio"
							onClick={handleClick}
						/>
						{subproduct.img && <img src={subproduct.img} />}
						<label
							htmlFor={"var-" + variants_category.id + "-" + subproduct.id}
							className="variant-title"
						>
							{subproduct.name}
							{variants_category.has_price == 1 && (
								<span className="small">
									{" +" + currencyFormat(subproduct.price)}
								</span>
							)}
						</label>
					</div>
				))}
			</div>
		</div>
	);
}

export default Variants;
